import React from 'react';

const Footer = () => {
    return (
        <footer id="footer" className="footer">

			<div className="footer-content position-relative">
				<div className="container">
					<div className="row text-center">

						<div className="col-lg-4 col-md-3">
							<div className="footer-info">
								<a href="/" className="logo d-inline-flex align-items-center">
									<img src="/assets/img/logo.png" alt="" />
								</a>
							</div>
						</div>

						<div className="col-lg-2 col-md-3 footer-links">
							<ul>
								<li><a href="/the_fc_experience">The FC Experience</a></li>
								<li><a href="#memberships">Memberships</a></li>
								<li><a href="/schedule">Schedule</a></li>
								
							</ul>
						</div>

						<div className="col-lg-2 col-md-3 footer-links">
							<ul>
								<li><a href="#personal_training">Personal Training</a></li>
								<li><a href="#location">Location</a></li>
								<li><a href="#us">Us</a></li>
							</ul>
						</div>

						<div className="col-lg-4 col-md-3">
							<div className="footer-info">
								<p>
									<strong>Address:</strong> 875 Garnet Ave, San Diego, CA 92109<br/><br/>
									<strong>Phone:</strong> <a href="tel:+18589334553" target="_blank">+1 (858) 933 4553</a><br/>
								</p>

								<div className="social-links d-inline-flex mt-5">
									<a href="#" className="d-flex align-items-center justify-content-center"><i className="bi bi-facebook"></i></a>
									<a href="#" className="d-flex align-items-center justify-content-center"><i className="bi bi-yelp"></i></a>
									<a href="#" className="d-flex align-items-center justify-content-center"><i className="bi bi-instagram"></i></a>
								</div>								
							</div>
						</div>

					</div>
				</div>
			</div>

			<div className="footer-legal text-center position-relative">
				<div className="container">
					<div className="row">
						<div className="col-md-12 mb-5 mt-5">
							<p>*1 free week applies to open gym access only. 1 free class included during your free open gym week.</p>
						</div>

						<div className="col-md-6">
							<p>© { new Date().getFullYear() } FITNESS COLLECTIVE. ALL RIGHT RESERVED.</p>
						</div>

						<div className="col-md-6">
							<p>TERMS OF USE - PRIVACY POLICY - ADA ACCESSIBILITY</p>
						</div>
					</div>
				</div>
			</div>

		</footer>
    )
}

export default Footer;