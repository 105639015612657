import React from 'react';

import { useLocation } from 'react-router-dom';

const Header = () => {
	
	const location = useLocation();

    return (
        <header id="header" className="header d-flex align-items-center">
			<div className="container-fluid container-xla d-flex align-items-center justify-content-between px-5">
				<a href="/" className="logo d-flex align-items-center">
					<img src="/assets/img/logo.png" alt="" />
				</a>

				<i className="mobile-nav-toggle mobile-nav-show nav-open bi bi-list"></i>
				<i className="mobile-nav-toggle mobile-nav-show d-none nav-close bi bi-x"></i>
				
				<nav id="navbar" className="navbar">
					<ul>
						<li><a href="#the_fc_experience">THE FC EXPERIENCE</a></li>
						<li><a href="#memberships">MEMBERSHIPS</a></li>
						<li><a href="#schedule">SCHEDULE </a></li>
						<li><a href="#personal_training">PERSONAL TRAINING</a></li>
						<li><a href="#location">LOCATION</a></li>
						<li><a href="#us">US</a></li>
					</ul>
				</nav>

				<a href="#free_trial" className="trial mobile-nav-hide d-flex align-items-center">
					<h4>FREE TRIAL</h4>
				</a>
			</div>
		</header>
    )
}

export default Header;